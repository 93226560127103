import { MCNAccount, MCNProvider, MCNVault, MCNWallet } from "juneojs";
import { create } from "zustand";

import useNetworkStore, { getDefaultProvider } from "./NetworkStore";

interface AccountState {
  masterWallet: MCNWallet[] | undefined;
  setMasterWallet: (wallet: MCNWallet[] | undefined) => void;

  isConnected: boolean;
  setIsConnected: (connected: boolean) => void;

  mcnAccount: MCNAccount;
  setMcnAccount: (account: MCNAccount) => void;

  mcnVault: MCNVault | undefined;
  setMcnVault: (vault: MCNVault) => void;

  evmAddress: string;
  setEvmAddress: (address: string) => void;

  jvmAddress: string;
  setJvmAddress: (address: string) => void;

  platformAddress: string;
  setPlatformAddress: (address: string) => void;

  initializeAccount: (provider: MCNProvider) => void; // Function to initialize the store based on `masterWallet`

  initializeNetworkInformations: () => void; // Function to initialize the store based on `provider`
}

const provider = getDefaultProvider();

const useAccountStore = create<AccountState>((set, get) => ({
  masterWallet: undefined,
  setMasterWallet: (wallet) => {
    set({ masterWallet: wallet });
    get().initializeAccount(provider);
  },

  isConnected: false,
  setIsConnected: (connected) => set({ isConnected: connected }),

  // default value to avoid undefined errors
  mcnAccount: new MCNAccount(provider, provider.mcn.generateWallet(12)),
  setMcnAccount: (account) => set({ mcnAccount: account }),

  mcnVault: undefined,
  setMcnVault: (vault) => set({ mcnVault: vault }),

  evmAddress: "",
  setEvmAddress: (address) => set({ evmAddress: address }),

  jvmAddress: "",
  setJvmAddress: (address) => set({ jvmAddress: address }),

  platformAddress: "",
  setPlatformAddress: (address) => set({ platformAddress: address }),

  initializeAccount: (provider: MCNProvider) => {
    const {
      masterWallet,
      setMcnAccount,
      setMcnVault,
      setEvmAddress,
      setJvmAddress,
      setPlatformAddress,
    } = get();

    if (!masterWallet || masterWallet.length === 0) {
      return;
    }

    const mainWallet = masterWallet[0];
    const additionalWallets = masterWallet.slice(1);

    const mcnAccount = new MCNAccount(provider, mainWallet);
    const mcnVault = new MCNVault(provider, mainWallet, additionalWallets);

    setMcnAccount(mcnAccount);
    setMcnVault(mcnVault);

    setEvmAddress(mcnAccount.getAccount(provider.mcn.primary.june.id).address);
    setJvmAddress(mcnAccount.getAccount(provider.mcn.primary.jvm.id).address);
    setPlatformAddress(
      mcnAccount.getAccount(provider.mcn.primary.platform.id).address,
    );
  },

  initializeNetworkInformations: () => {
    const {
      mcnAccount,
      masterWallet,
      setEvmAddress,
      setJvmAddress,
      setPlatformAddress,
    } = get();

    const { provider } = useNetworkStore.getState();
    if (!masterWallet || masterWallet.length === 0) {
      return;
    }

    setEvmAddress(mcnAccount.getAccount(provider.mcn.primary.june.id).address);
    setJvmAddress(mcnAccount.getAccount(provider.mcn.primary.jvm.id).address);
    setPlatformAddress(
      mcnAccount.getAccount(provider.mcn.primary.platform.id).address,
    );
  },
}));

export default useAccountStore;
