import {
  Blockchain,
  ERC20Asset,
  JNTAsset,
  JRC20Asset,
  JVM_ID,
  MCNProvider,
  PLATFORMVM_ID,
  WrappedAsset,
} from "juneojs";
import { create } from "zustand";

import { Token } from "../types/Token";

interface TokensState {
  tokenList: Token[];
  setTokenList: (tokenList: Token[]) => void;

  initializeTokens: (chainDict: Blockchain[], provider: MCNProvider) => void;
}
const useTokensStore = create<TokensState>((set, get) => ({
  tokenList: [],
  setTokenList: (tokenList: Token[]) => set({ tokenList }),

  // set tokens for this network
  initializeTokens: (chainDict: Blockchain[], provider: MCNProvider) => {
    const { setTokenList } = get();

    const newTokenList = getTokenArray(chainDict, provider);

    setTokenList(newTokenList);
  },
}));

// this is the list of blockchains that are supported by the platform
// we get the list from the provider

// boucle on array of chainDict
// for each natif, create a json object with the following fields:
// defaultBlockchain, address, assetId, destinationCross, tokenAsset
export const getTokenArray = (
  chainDict: Blockchain[],
  provider: MCNProvider,
) => {
  const tokenArray: Token[] = [];

  for (let i = 0; i < chainDict.length; i++) {
    // we add the JUNE token of the platform and the JVM chain
    if (chainDict[i].vm.id === PLATFORMVM_ID || chainDict[i].vm.id === JVM_ID) {
      const dict: Token = {
        defaultBlockchain: chainDict[i],
        address: chainDict[i].assetId,
        assetId: chainDict[i].assetId,
        destinationCross: provider.mcn.primary.june.id,
        tokenAsset: chainDict[i].asset,
      };
      tokenArray.push(dict);
    }
    // if the blockchain is not the platform or the JVM chain, we add the native token and the jrc20 tokens
    else {
      for (const asset of chainDict[i].getRegisteredAssets()) {
        let address = "";
        let destinationCross: string | undefined = "";
        let assetId = "";

        if (asset.type === "gas") {
          address = chainDict[i].assetId;
          assetId = chainDict[i].assetId;
          if (chainDict[i].id === provider.mcn.primary.june.id) {
            destinationCross = provider.mcn.primary.jvm.id;
          } else {
            destinationCross = provider.mcn.primary.june.id;
          }
        } else if (asset.type === "wrapped" && asset instanceof WrappedAsset) {
          address = asset.address;
          destinationCross = undefined;
          assetId = asset.assetId;
        } else if (asset.type === "jrc20" && asset instanceof JRC20Asset) {
          address = asset.address;
          destinationCross = getChainIdFromAssetId(asset.nativeAssetId);
          assetId = asset.nativeAssetId;
        } else if (asset.type === "erc20" && asset instanceof ERC20Asset) {
          address = asset.address;
          destinationCross = undefined;
          assetId = asset.assetId;
        } else if (asset.type === "jnt" && asset instanceof JNTAsset) {
          continue;
        } else {
          // we don't add the asset to the list
          continue;
        }

        const dict: Token = {
          defaultBlockchain: chainDict[i],
          address: address,
          assetId: assetId,
          tokenAsset: asset,
        };
        if (destinationCross !== undefined) {
          dict.destinationCross = destinationCross;
        }
        tokenArray.push(dict);
      }
    }
  }

  function getChainIdFromAssetId(assetId: string) {
    let chainId = "";
    for (let i = 0; i < chainDict.length; i++) {
      if (chainDict[i].assetId === assetId) {
        chainId = chainDict[i].id;
        break;
      }
    }
    return chainId;
  }

  // we put the JUNE token at the top of the list
  const juneIndex = tokenArray.findIndex(
    (dict) =>
      dict.assetId === provider.mcn.primary.june.assetId &&
      dict.defaultBlockchain.id === provider.mcn.primary.june.id,
  );
  if (juneIndex > -1) {
    const [juneElement] = tokenArray.splice(juneIndex, 1);
    tokenArray.unshift(juneElement);
  }

  return tokenArray;
};

export default useTokensStore;
